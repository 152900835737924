import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import {useNavigate} from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../shared/context/auth-context';
import axios from 'axios';
import FirstTimeRegister from '../components/FirstTimeRegister';
import VinInputForm from '../components/VinInputForm';
import {useRecoilValue } from 'recoil';
import { ToastContainer} from 'react-toastify';
import firstTimeRegisterAtom from '../atoms/firstTimeRegister';
import KPIShow from '../components/KPIShow';
import { BASE_URL } from '../components/lib/basic';
const DashBoard = () => {

    
    const auth = useContext(AuthContext);

    const firstTimeRegisterVisible = useRecoilValue(firstTimeRegisterAtom);
    
    const navigate = useNavigate();

    if(!auth.isLoggedIn)
    {
      navigate("/", { replace: true });
    }

      // if(JSON.parse(localStorage.getItem("userData")).token == null)
      // {
      //   navigate("/",{replace:true});
      // }

  useEffect(()=>{
    const dashBoardPage=async ()=>{
      try{   
         const res = await axios.get(`${BASE_URL}/users/dashboard`,{
        headers:{
          Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
          }
        });
             
          if(!res.status === 200){
             const err =new  Error(res.err);
             throw err;
          }

        }

      catch(err){
        
        console.log(err);
        navigate("/", { replace: true })

      }
      }
      dashBoardPage();
  
  },[]);

  console.log();
  return (
      <>
      
        <Navbar />
            <h1>Welcome <b className='underline'>{JSON.parse(localStorage.getItem('userType')).userName}</b></h1>
           {JSON.parse(localStorage.getItem('userType')).userRole!=="admin" ? (
          <div className='flex flex-col justify-center items-center mt-10'>
            { firstTimeRegisterVisible ? <FirstTimeRegister /> : <VinInputForm />}
            <ToastContainer />
        </div>

        ): <KPIShow />} 
      </>
    
  )
}

export default DashBoard;