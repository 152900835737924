import React,{useState} from 'react';
import { useRecoilState} from 'recoil';
import vinDetailsAtom from '../atoms/vinDetails';
import moment from 'moment';
import axois from 'axios';
import { toast } from 'react-toastify';
import deleteModalAtom from '../atoms/deleteModalAtom';
// import editVinNumberData from '../atoms/deleteModalAtom';
// import editedVinsState from '../atoms/editedVinsState';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from './DeleteModal';
import { BASE_URL } from './lib/basic';
// import confirmModalAtom from '../atoms/confirmDeleteState';
// import { BASE_URL } from './lib/basic';
const VinTable = () => {
  
    // Show Edit Modal 
    const [showModal,setShowModal] = useRecoilState(deleteModalAtom);
    // const [confirmModal,setConfirmModal] = useRecoilState(confirmModalAtom);

    // Selected Vin Details.
    // const [editVinDetails,setEditVinDetails] = useRecoilState(editVinNumberData);

    // Edited Vins Data
    // const [editedVinData,setEditedVinData]= useRecoilState(editedVinsState);

    // Order Details
    // const [orderPlaced,setOrderPlaced] = useState(false);
  
    // Get the Current User ID
 

  // VIN Details ATOM State
    const [vinLists,setVinLists] = useRecoilState(vinDetailsAtom);
    const [selectedVin,setSelectedVin] = useState(null);
  // Get the No. of Passanger Side AirBags
  // Order Details ATOM State:-
  const resetHandler = async()=>{
    setVinLists([]);
  };
  const orderHandler=async()=>{ 
          const resposnse = await axois.post(`${BASE_URL}/order/create`,{orderDetails:{
            userId:JSON.parse(localStorage.getItem('userData')).userId,
            orderNumber:`NS${new Date().getTime()}${JSON.parse(localStorage.getItem('userData')).userId}`,
            creationDate:moment().format('Do-MMM-YYYY h:mm a'),
            VinInfo:vinLists
          }},{
            headers:{
              Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
            }
          });
          if(resposnse)
            {
              setVinLists([]);
            }
            if(resposnse.status === "201")
              {
                toast.success("Order Placed",{
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
          }
          else
          {
            toast.success(resposnse.data.message,{
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
  };

  const editHandler =(vin)=>{
    
    setShowModal(true);
    setSelectedVin(vin);

  };
  
  return (
    <>
      {showModal ? <DeleteModal vin={selectedVin}/>: " "}
    <div className="flex flex-col">
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-hidden">
          <table className="min-w-full">
            <thead className="bg-white border-b">
              <tr>
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                  Passenger Side
                </th>
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                  Driver Side
                </th>
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Vehicle Identification Number (VIN)
                </th>
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                  Car Company
                </th>
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                  Car Model
                </th>
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                  Model Year
                </th>
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                 Action
                </th>
              </tr>
            </thead>
            <tbody>
         {  vinLists.length > 0   &&
                vinLists.map((vinList)=>(
                  <tr className="bg-gray-100 border-b">
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {vinList.passangeSide}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {vinList.driverSide}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {vinList.vinNumber}
                      </td>    
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {vinList.carCompany}
                      </td> 
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {vinList.carModel}
                      </td> 
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {vinList.carModelYear}
                      </td> 
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <p className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded cursor-pointer' onClick={()=>{editHandler(vinList);}}>Delete</p>
                      </td>          
                </tr>
                ))
              } 
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div className='text-center'>
      <button type="button" className='ml-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded' onClick={orderHandler}>Create Order</button>
      <button type="button" className='ml-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded' onClick={resetHandler}>Reset Order</button>
    </div>
  </div>
  </>
  )
}

export default VinTable;