import React from 'react';
import Navbar from '../components/Navbar';
import UserOrderTable from '../components/UserOrderTable';
const MyOrders = () => {
  return (
      
        <>
            <Navbar />
            <div className='text-center mt-2 mb-0'>
              <h1 className='m-0 p-0  font-bold'>My Orders</h1>
            </div>
            <UserOrderTable />
        </>
        )};

export default MyOrders;