import React from 'react'
import LoginForm from '../components/LoginForm';

const SignIn = () => {
  return (
    <div className='bg-carImage bg-cover min-h-fit justify-center items-center'> 
        <LoginForm />
    </div>    
  )
}

export default SignIn;