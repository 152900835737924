import React from 'react'
import HubspotUserTable from '../components/HubspotUserTable';
import Modal from '../components/Modal';
import Navbar from '../components/Navbar';
import { useRecoilValue} from 'recoil';
import showModalAtom from '../atoms/ModelState';

const HubspotData = () => {
  const showModal =useRecoilValue(showModalAtom);
  return ( 
        <>
        <Navbar />
        <HubspotUserTable />
      { showModal && <Modal />}
        </>
  )
}

export default HubspotData;