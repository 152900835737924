import React,{useEffect, useState} from 'react';
import { useSetRecoilState,useRecoilValue} from 'recoil';
// import Scanner from "../components/Scanner";
import VinTable from './VinTable';
// import  Quagga  from 'quagga';
import { FaCloudDownloadAlt } from "react-icons/fa"
import docFile from "../assets/New_ShippingandPackaging_Instructions.pdf"
import vinDetailsAtom from '../atoms/vinDetails';
import validateVin from '../helpers/vin-validation';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserPrevOrder from './UserPrevOrder';
import { BASE_URL } from './lib/basic';
const VinInputForm = () => { 

    
      // Error Messages in VIN Form
    const [error,setError] =useState("");

    // Storing the VIN Details
    const[passangeSideValue,setPassangerSideValue] = useState("No");
    const[driverSideValue,setDriverSideValue] = useState("No");
    
    // Car Company State 
    const [carCompany,setCarCompany] = useState("");
    const [carModelYear,setCarModelYear] = useState("");
    // Car Company Array State From DataBase

    // const [carCompanyArray,setCarCompanyArray] = useState([]);

    // Car Modal Array State From DataBase

    // const [carModelArray,setcarModelArray] = useState([]);

    // Car Modal Name State From User 
    const [carModelName,setcarModelName] = useState("");

    // Car Modal Array Year State From  DataBase
    // const [carModelYearArray,setcarModelYearArray] = useState([]);

    // Show Model,ModelYear and CarCompany status
    const [showDetails,setShowDetails] =useState(false);

    // Car Model Id

    const [carModelId,setCarModelId] = useState("1");

    // Button Disabled
    const [disabled,setDisabled] = useState(true);
    const [driveSideAvailable,setDriverSideAvailable] = useState(false);
    const [passangerSideAvailable,setPassangerSideAvailable] = useState(false);
    //Storing the State of the Scanner 
    const [result,setResult]= useState("");
    // const [camera, setCamera] = useState(false);
    // const [buttonDisable,setButtonDisabled] = useState(false);

    const [isVINValid,setVINValid] = useState(false);
    const [message,setMessage] = useState("");
    const [errorMessage,setErrorMessage] = useState(false);

    const onDetected = result => {
        setResult(result);
      };

    // Use Recoil State
    const vinDetails =useRecoilValue(vinDetailsAtom);
    const setVinDetails =useSetRecoilState(vinDetailsAtom);
    
   
    // Get Car Vin Details
    const checkVIN = async(result)=>{
      const resp = await axios.post(`${BASE_URL}/order/validateVIN`,{vin:result},{
              headers:{
                  Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
             }});
            
             if(resp.status == "200")
              {
                
                if(resp.data.length == 2)
                {
                    setDriverSideAvailable(true);
                    setPassangerSideAvailable(true);
                    setPassangerSideValue("Yes");
                    setDriverSideValue("Yes");
                }
                else{
                  if(resp.data[0].airbagType == "Passenger side airbag")
                  {
                    setDriverSideAvailable(false);
                    setPassangerSideAvailable(false);
                    setPassangerSideValue("Yes");
                    setDriverSideValue("No");
                  }
                  else
                  {
                    setDriverSideAvailable(false);
                    setPassangerSideAvailable(false);
                    setPassangerSideValue("No");
                    setDriverSideValue("Yes");
                  }
                }
                setCarModelYear(resp.data[0].modelYear);
                if(resp.data[0].seriesCode == "FX" ||resp.data[0].seriesCode == "M" || resp.data[0].seriesCode == "QX4" ||resp.data[0].seriesCode == "I30/I35")
                  {
                    setCarCompany("Infiniti");
                  }
                  else
                  {
                    setCarCompany("Nissan");
                  }
                  setcarModelName(resp.data[0].seriesCode);
                  setCarModelId(resp.data[0].modelId);
                setShowDetails(true);
                setVINValid(true);
                setMessage("This VIN is Eligible for BuyBack Program");
                setErrorMessage(false);
                setVINValid(true);
                
              }
              else
              {
                setShowDetails(false);
                setVINValid(false);
                setErrorMessage(true);
                setPassangerSideAvailable(false);
                setDriverSideAvailable(false);
               setMessage("This VIN is Not Eligible for BuyBack Program");
              }
             
    };

    // Passander Side Handler
  
    const passangerHandler = (e)=>{
      // console.log(e.target.value);
      setPassangerSideValue(e.target.value);
      
    };

    // Driver Side Handler
    const driverHandler = (e)=>{
      // console.log(e.target.value);
      setDriverSideValue(e.target.value);
    };
    // VIN Exist or Not in the React State.

    const vinExists = (vin)=>{
      return vinDetails.some(function(el) {
        return el.vinNumber === vin;
      }); 
    };

    // Add VIN Handler Function
    const addVinHandler = async(event)=>{
        event.preventDefault();
        setShowDetails(false);
        if(result)
        { 
          if(passangeSideValue == "No" && driverSideValue == "No")
                        {
                          
                            toast.error("Please Select Any One Side",{
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                      });

                        }
         else if(validateVin(result))
            {
              const response =  await axios.post(`${BASE_URL}/order/duplicatevin`,{vinNumber:result,passangeSide:passangeSideValue,driverSide:driverSideValue},{
                headers:{
                    Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
               }});
               
              if(response.status == "200")
               {    
                 if(isVINValid)
                  {
                    if(!vinExists(result))
                    {   
                          setVinDetails((oldVinDetails)=>[
                            ...oldVinDetails,
                            {
                              passangeSide:passangeSideValue,
                              driverSide:driverSideValue,
                              vinNumber:result,
                              carCompany:carCompany,
                              carModel:carModelName,
                              carModelYear:carModelYear,
                              carModelId:carModelId
                             }
                       ]);
                     }

                     else
                     {
                      toast.error("VIN already Added in the List",{
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                     }
              } 
              else
              {
                toast.error("VIN is not Validated",{
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setDisabled(false);
              }
                setError('');
               }
               else
               {
                toast.error("Order Creation Failed: VIN already exists in another Order",{
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setError('');
               }
            }
            else
            {
              setError("Please Enter the Valid VIN");
            }  
        }
        else
        {
            setError("Please Add the VIN Number");
        }  
        setResult('');
      };
      useEffect(()=>{
        if(result.length == 17)
          {
            checkVIN(result);
          }
      },[result]);

      useEffect(()=>{},[showDetails])
  return (
    <>
    <div className='contianer'>
        <h1 className='text-xl text-center font-bold text-gray-700'>VIN Details</h1>
      <form onSubmit={addVinHandler}>
        <div className='w-[37rem]'>
            <div className='flex mr-5 flex-col mt-6 w-[100%]' >
                <input type="text" required name="vinNumber" id="vinNumber" placeholder="Enter the Vehicle Identification Number (VIN)" className={error.length>0 ? 'border-red-500 ml-2 mr-2' :'ml-2 mr-2'} value={result} onChange={(e)=>{setResult(e.target.value)}} />
               {(isVINValid && result.length ==17) ? (<span className='text-green-600 ml-2 text-lg'>{message}</span>) : "" }
               {(errorMessage && result.length ==17) ? (<span className='text-red-600 ml-2 text-lg'>{message}</span>) : "" }
            </div>
      {  showDetails && result.length == 17 &&(<div className='flex mt-6 text-center'>
           {/* <div className='mr-2'>
           <p className='font-bold'>Driver Side: <span className='font-medium text-blue-900'>{driverSideValue}</span></p>
         </div>
         <div className='mr-2'>
           <p className='font-bold'>Passenger Side: <span className='font-medium text-blue-900'>{passangeSideValue}</span></p>
         </div>    */}
          <div className='mr-2'>
            <p className='font-bold'>Car Company: <span className='font-medium text-blue-900'>{carCompany}</span></p>
          </div>
          <div className='mr-2'>
            <p className='font-bold'>Car Model: <span className='font-medium text-blue-900'>{carModelName}</span></p>
          </div>
          <div className='mr-2'>
            <p className='font-bold'>Model Year: <span className='font-medium text-blue-900'>{carModelYear}</span></p>
          </div> 
         <div className='flex justify-between'>
                <div>
                  <label for="passangerSide" className='font-bold'>Passenger Side</label>
                    <select name="passangerSide" id="passangerSide" onChange={passangerHandler} disabled={!passangerSideAvailable}>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                </div>
                <div>
                  <label for="driverSide" className='font-bold'>Driver Side</label>
                    <select name="driverSide" id="driverSide" value={driverSideValue} onChange={driverHandler} disabled={!driveSideAvailable}>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                </div>      
          </div>
          </div>
          
          ) 
      } 
          
          <div className='flex justify-center h-[40px] mt-6'>
            <button type="submit" disabled={(!isVINValid || result.length !=17)} className={(!isVINValid || result.length !=17) ?' w-[7rem] ml-2 bg-gray-300  text-white-500 font-semibold px-4 border border-gray-400 rounded cursor-not-allowed' : 'ml-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white px-4 border border-blue-500 hover:border-transparent rounded'}>
                Add VIN</button>
          </div>

        </div>
      </form>
      <div className='text-center mt-3'>
        <a className='flex justify-center text-xl text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 cursor-pointer gap-4' href="https://drive.google.com/file/d/14XJjvzNNrN0mRHF59C28PSEjAaYGaBb1/view" target="_blank">Download the Shipping Instructions <FaCloudDownloadAlt className='h-8 w-8'/></a>
      </div>  
        
      <ToastContainer />
    </div>
     {(vinDetails.length >0) ? <VinTable /> : <UserPrevOrder />}
    </>
  )
}
export default VinInputForm;