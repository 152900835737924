import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ModalTable from './ModalTable';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import nissanLogo from "../assets/nissanLogo.png";
import infinitiLogo from "../assets/infinitiLogo.png";
import { BASE_URL } from './lib/basic';
const InfoTable = () => {

    const [carModelsNissan,setCarModelsNissan] = useState([]);
    //  Nissan Car Models

    const [carModelInfiniti,setCarModelInfiniti] = useState([]);

    const getAllCarModels = async ()=>{
        const response = await axios.get(`${BASE_URL}/order/carModels`,{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }});
         setCarModelsNissan(response.data);
        };

    const getAllCarModelInfiniti = async()=>{

        const response = await axios.get(`${BASE_URL}/order/carModelsInfiniti`,{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }});
        setCarModelInfiniti(response.data);

    };

    useEffect(()=>{
        getAllCarModels();

    },[]);

    useEffect(()=>{

        getAllCarModelInfiniti();

    },[]);

  return (
      <>
        <Navbar />
        <h1 className='text-center mt-20 text-xl'>Models Eligible for Buyback Program</h1>
        <div className='flex mt-10 items-center'>
            <ModalTable carModels={carModelsNissan} title="Nissan" color="text-blue-900" logo={nissanLogo}/>
            <ModalTable carModels={carModelInfiniti} title="Infiniti" color="text-green-900" logo={infinitiLogo} margin="-mt-[2rem]" />
        </div>
        <div className='text-center mt-4'>
        <Link to="/dashboard" className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer '>Click Here to Create Your Order.</Link>
      </div>
      </>
   
  )
}
export default InfoTable;