import axios from 'axios';
import React,{useEffect,useState} from 'react';
import { useRecoilState} from 'recoil';
import showModalAtom from '../atoms/ModelState';
import hubSpotUserDataAtom from '../atoms/hubData';
import { BASE_URL } from './lib/basic';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'react-uuid';
const HubspotUserTable = () => {
    const [userData,setUserData] = useState([]);

    const [inviteTrigger,setInviteTrigger] = useState(true);

    const [showModal,setShowModal] = useRecoilState(showModalAtom);
    
    const [fullUserData,setFullUserData] = useRecoilState(hubSpotUserDataAtom);
    let [activeIndex,setCurrentActiveIndex]=useState(0)
    const [pageArray,setPageArray]=useState([1])
    const [currentActiveAfter,setCurrentActiveAfter]=useState(1)
    let [isNextDisabled,setIsNextDisabled]=useState(false)
    let [isPrevDisabled,setIsPrevDisabled]=useState(true)
    let [clickedOnce,setIsClickedOnce]=useState(false)
    let [showLoading,setIsShowLoading]=useState(true)

    useEffect(()=>{
           axios.get(`${BASE_URL}/api/hubspot/1`,{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }
           }).then(res=>{
            //    setUserData(res.data);
            if(res.data.results)
            {
                let resArray=[]
               for(let el of res.data.results){
                let obj={
                email:el.properties.email,
                fullName:el.properties.firstname,
                status:el.properties.status,
               id:el.id
               }
               resArray.push(obj)
            }
            setUserData(resArray)
            setIsShowLoading(false)
            }
           console.log(userData)
           let after=res.data.paging?.next?.after
           console.log(after)
           if(after){
           if(!(after in userData) ){
               console.log('adding')
               setPageArray(oldArray => [...oldArray, after])
               }
             
          }
       else{
           setIsNextDisabled(true)
       }})
      
    },[]);




    const getPaginatedUserRecords=async(after)=>{
      await  axios.get(`${BASE_URL}/api/hubspot/${after}`,{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }
           }).then(res=>{
            //    setUserData(res.data);
            if(res.data.results)
            {
                let resArray=[]
               for(let el of res.data.results){
                let obj={
                email:el.properties.email,
                fullName:el.properties.firstname,
                status:el.properties.status,
               id:el.id
               }
               resArray.push(obj)
            }
            setUserData(resArray)
            setIsClickedOnce(false)
            setIsShowLoading(false)
            }
           
            console.log(userData)
            let after=res.data.paging?.next?.after
            console.log(after)
            if(after){
            if(!(after in userData) ){
                console.log('adding')
                setPageArray(oldArray => [...oldArray, after])
                
                }
              
           }
        else{
            setIsNextDisabled(true)
        }
        }).catch(err=>{
               console.log(err);
           })

    }

    const nextButton= async()=>{
        setIsPrevDisabled(false)
        if(clickedOnce==false){
            setIsClickedOnce(true)
            setIsShowLoading(true)
        setCurrentActiveIndex(++activeIndex)
        setCurrentActiveAfter(pageArray[activeIndex])
        let after=pageArray[activeIndex]
        console.log('actviindex',activeIndex)
        console.log('pagearray',pageArray)
        console.log('stateafter',currentActiveAfter)
        console.log('after',after)
        await getPaginatedUserRecords(after)
        }
    }
    const prevButton= async()=>{
        setIsNextDisabled(false)
        if(clickedOnce==false){
            setIsClickedOnce(true)
            setIsShowLoading(true)
        setCurrentActiveIndex(--activeIndex)
        setCurrentActiveAfter(pageArray[activeIndex])
        let after=pageArray[activeIndex]
        console.log('actviindex',activeIndex)
        console.log('pagearray',pageArray)
        console.log('stateafter',currentActiveAfter)
        console.log('after',after)
        await getPaginatedUserRecords(after)
        if(activeIndex<=0)
        {setIsPrevDisabled(true)}
       
        }
        
    }

    const getUserDetailByEmail = async (user)=>{
           const response =  await axios.post(`${BASE_URL}/api/hubspotById`,{id:user.id},{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }
           });
           if(response.data)
           {
               setShowModal(true);
           }
           setFullUserData(response.data);
    };

    const inviteUser =(user)=>{
       axios.post(`${BASE_URL}/api/inviteUserByContactId`,{contactId:user.id},{
            headers:{Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`}
        }).then(res=>{
            setInviteTrigger(!inviteTrigger);
           
            toast.success('User Successfully Invited',{
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }).catch((err)=>{
            if(err)
                { 
                    toast.error('User Already Registered!!', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                      
                }
            
        })
            
    
        setInviteTrigger(!inviteTrigger);
        
    };

  return (
    <div className="flex flex-col mt-5">
        <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow-md sm:rounded-lg">
                <table className="min-w-full">
                    <thead className="bg-gray-200 dark:bg-gray-700">
                        <tr>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Email
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Full Name
                            </th>     
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Info
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Invite
                            </th>
                           
                        </tr>
                    </thead>
                    <tbody>
                    {userData.map((user)=>(
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={uuid()}>
                            <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.email}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                    {user.fullName}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                <button type='button' onClick={()=>{getUserDetailByEmail(user)}} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>More Info</button>
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                <button type="button" disabled={user.status!=='Invite'} onClick={()=>{inviteUser(user)}} 
                                 className={user.status === 'Invited' ? 'bg-gray-500 text-white font-bold cursor-no-drop py-2 px-4 rounded' :'bg-blue-500 hover:bg-blue-800 text-white font-bold cursor-pointer py-2 px-4 rounded'}>{user.status}</button>
                            </td>
                            </tr>
                    ))}       
                    </tbody>
                </table>
                <div className='inline-block py-2 min-w-full sm:px-6 flex lg:px-8 '>
        {/* <p>Page {currentPage+1}/{totalNumberOfPages}</p> */}
        <p><b>Page {activeIndex+1}</b></p>
    </div>
    <div className="flex flex-col items-center">
                 <span className="text-sm text-gray-700 dark:text-gray-400 mt-">
                </span>
    {!showLoading?<div className='inline-block py-6 min-w-full sm:px-6 flex lg:px-8 text-center space-x-5 justify-center'>
    <button type='button' onClick={prevButton} className={isPrevDisabled ? 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-white-500 bg-gray-400 rounded-lg border border-gray-300 opacity-70 cursor-no-drop dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400  dark:hover:text-white'
      : 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'} disabled={isPrevDisabled}>
                        <svg className="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
                            Previous</button>
                      <button type='button' onClick={nextButton} 
                      className={isNextDisabled ? 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-white-500 bg-gray-400 rounded-lg border border-gray-300 opacity-70 cursor-no-drop dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400  dark:hover:text-white'
                      : 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'} disabled={isNextDisabled}>
                          Next
                        <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                       </button>


    </div>:'Loading Records .....'}
    {/* {showLoading?<div className='inline-block py-6 min-w-full sm:px-6 flex lg:px-8 text-center space-x-5 justify-center'>Loading.....</div>:null} */}
    </div>
         <ToastContainer />
        </div>
    </div>
</div>
  )
}

export default HubspotUserTable