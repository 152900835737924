import React,{useState} from 'react';
import { useRecoilState } from 'recoil';
import hubSpotUserDataAtom from '../atoms/hubData';
import showModalAtom from '../atoms/ModelState';
const Modal = () => {

    const [showModal,setShowModal] = useRecoilState(showModalAtom);
    const [userData,setUserData] = useRecoilState(hubSpotUserDataAtom);
    

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

  
    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

   
    <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">User Details</h3>
            <div className="mt-3">
                <div className='flex'>
                        <h1 className='mr-2 font-medium'>FullName:</h1> <p>{userData.fullname}</p>
                </div>
                <div className='flex'>
                        <h1 className='mr-2 font-medium'>Company:</h1> <p>{userData.company}</p>
                </div>
                <div className='flex'>
                        <h1 className='mr-2 font-medium'>Email:</h1> <p>{userData.email}</p>
                </div>
                <div className='flex'>
                        <h1 className='mr-2 font-medium'>Phone:</h1> <p>{userData.phone}</p>
                </div>
                <div className='flex'>
                        <h1 className='mr-2 font-medium'>Address:</h1> <p>{userData.address}</p>
                </div>
                <div className='flex'>
                        <h1 className='mr-2 font-medium'>City</h1> <p>{userData.city}</p>
                </div>
                <div className='flex'>
                        <h1 className='mr-2 font-medium'>State:</h1> <p>{userData.state}</p>
                </div>
                <div className='flex'>
                        <h1 className='mr-2 font-medium'>Postal/Zip Code:</h1> <p>{userData.zip}</p>
                </div>
                <div className='flex'>
                        <h1 className='mr-2 font-medium'>Country:</h1> <p>{userData.country}</p>
                </div>
                <div className='flex'>
                        <h1 className='mr-2 font-medium'>GST:</h1> <p>{userData.gst}</p>
                </div>  
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        {/* <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">Invite</button> */}
        <button type="button" onClick={()=>{setShowModal(false)}} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>
      </div>
    </div>
  </div>
</div>
  )
};

export default Modal;