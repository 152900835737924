import axios from 'axios';
import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import uuid from 'react-uuid';
import { BASE_URL } from './lib/basic';
const UserPrevOrder = () => {

    const [prevOrder,setPrevOrder] =useState([]);
    const [totalOrder,setTotalOrder] = useState(0); 
    const userOrder =async ()=>{

      const response = await axios.post(`${BASE_URL}/order/dashboardUserOrder`,{userId:JSON.parse(localStorage.getItem('userData')).userId},
      {
        headers:{
          Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
        }
      });
        
        if(response)
          {
            setPrevOrder(response.data);
          }

    };

    const getTotalOrders= async()=>{
      const response = await axios.post(`${BASE_URL}/order/totalOrders`,{userId:JSON.parse(localStorage.getItem('userData')).userId},
      {
        headers:{
          Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
        }
      });
        
        if(response)
          {
            setTotalOrder(response.data[0].total);
          }
    };
    useEffect(()=>{
      userOrder();
    },[]);
  
    useEffect(()=>{
      getTotalOrders();
    },[]);
  
  return (
    <div className='mt-9 w-full px-4'>

        <h1 className='p-3 rounded-r rounded-l text-xl'>Your Previous Orders:-</h1>
        <table className="min-w-full">
                    <thead className="bg-gray-400 dark:bg-gray-700">
                        <tr>
                            <th scope="col" className="py-3 font-medium px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Order Number
                            </th>
                            <th scope="col" className="py-3 font-medium px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Creation Date
                            </th>
                            <th scope="col" className="py-3 font-medium px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Order Status
                            </th>
                            <th scope="col" className="py-3 font-medium px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                               Total VIN's
                            </th>
                            <th scope="col" className="py-3 font-medium px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                               Total AirBags
                            </th>   
                        </tr>
                    </thead>
                    <tbody>
                    {prevOrder.map((order)=>(
                      
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={uuid()}>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                        {order.orderNumber}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                        {order.creationDate}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                        {order.name}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                        {order.totalvin}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                        {order.totalAirbags}
                            </td>
                        </tr>
                     
                    )) }
                    </tbody>              
      </table>
      <div className='text-center mt-4'>
        <Link to="/myorders" className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer '>See All (Total Orders-{totalOrder})</Link>
      </div>
    </div>
  )
}

export default UserPrevOrder;