import React from 'react';
import uuid from 'react-uuid';
const ModalTable = ({carModels,title,color,logo,margin}) => {
  return (
 <div className={`w-[50%] ${margin}`}>
        <div className="flex flex-col">
        <div className="w-min-full">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
            <div className='flex justify-center items-center mb-3'>
                {/* <h1 className='font-bold text-center mb-2 '>{title}</h1> */}
                <img src={logo} alt={title} className="w-20 h-15 ml-5"/>
            </div>
                <table className="min-w-full border cursor-pointer">
                <thead className="bg-white border-b">
                    <tr className='font-bold'>
                    <th scope="col" className="text-sm text-gray-900 px-6 py-4 text-left ">
                        S.No.
                    </th>
                    <th scope="col" className="text-sm text-gray-900 px-6 py-4 text-left">
                        Model
                    </th>
                    <th scope="col" className="text-sm text-gray-900 px-6 py-4 text-left">
                        Model Year
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {carModels.map((model,id)=>(
                         <tr className={`bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 font-medium ${color}`} key={uuid()}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm   font-bold">{id+1}</td>
                            <td className="text-sm px-6 py-4 whitespace-nowrap ">
                                    {model.model}
                            </td>
                            <td className="text-sm px-6 py-4 whitespace-nowrap">
                                    {model.modalRange}
                            </td>
                        </tr>
                    ))}
                </tbody>
                </table>
            </div>
            </div>
        </div>
        </div>
  </div>
  )
}

export default ModalTable;