import React from 'react';
import Navbar from '../components/Navbar';
import OrdersTable from '../components/OrdersTable';

const Orders = () => {

  return (
            <>
                <Navbar />
                <OrdersTable />
            </>
  )
}

export default Orders