import React, { useContext,useState } from 'react';
import { Formik,Form } from 'formik';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../shared/context/auth-context';
import {useRecoilState,useRecoilValue,useSetRecoilState } from 'recoil';
import initalUserDetailsAtom from '../atoms/intialUserDetails';
import firstTimeRegisterAtom from '../atoms/firstTimeRegister';
import userRoleAtom from '../atoms/userRole';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from './lib/basic';
import 'react-toastify/dist/ReactToastify.css';
const FirstTimeRegister = () => {

    const initalUserDetails = useRecoilValue(initalUserDetailsAtom);
    const setFirstTimeRegisterVisible = useSetRecoilState(firstTimeRegisterAtom);

    const [userRole,setUserRole] = useRecoilState(userRoleAtom);
    const navigate = useNavigate();

    const auth =useContext(AuthContext);
    // user Input data
    console.log(initalUserDetails);
    
    const [fullName,setFullName]=useState(initalUserDetails[0].fullName);
    const [companyName,setCompanyName] = useState(initalUserDetails[0].companyName);
    const [email,setEmail] =useState(initalUserDetails[0].email);
    const [mobile,setMobile] = useState(initalUserDetails[0].mobile);
    const [password,setpassword] = useState('');
    const [streetAddress,setstreetAddress]=useState(initalUserDetails[0].streetAddress);
    const [country,setCountry]= useState(initalUserDetails[0].country);
    const [state,setState]= useState(initalUserDetails[0].state);
    const [postalCode,setpostalCode] =useState(initalUserDetails[0].postalCode);
    const [city,setCity] =useState(initalUserDetails[0].city);
    const [gstNumber,setGstNumber] =useState(initalUserDetails[0].gstNumber);

    const registerFormHandler =async (event)=>{
       
        event.preventDefault();
        try{
        const res = await axios.post(`${BASE_URL}/users/changePassword`,{email:email,password:password},{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
            }
         });
         if(!res.status === 200){
            const err =new  Error(res.err);
            throw err;
         }
         else{
            
            toast.success("Password Changed",{
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            setUserRole("customer");
            setFirstTimeRegisterVisible(false);
            navigate("/dashboard",{replace:true});
         }
            }
            catch(err){
                toast.success("User Register Failed",{
                    position: "top-center",
                    autoClose: 9000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });

            }


    }; 
    console.log("First Time Register Page");
  return (
    <div className='flex content-center ml-50'>    
        <Formik>
            {formik=>(
                <div>
                <h1 className='my-4 font-weight-bold-display-4'>Sign Up </h1>
              
                    <Form className='w-full max-w-lg content-center' onSubmit={registerFormHandler}>
                        <div className='flex flex-wrap mx-3 mb-6'>              
                        <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                            <label htmlFor="fullname" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Full Name</label>
                            <input 
                                // className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`}
                                className='appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                                type="text" placeholder="Abhishek"
                                autoComplete='off'
                                value={fullName}         
                                disabled
                            />  
                        </div>
                            <div class="w-full md:w-1/2 px-3">
                            <label htmlFor="fullname" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Company Name</label>
                                <input       
                                    className='appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                                    type="text" placeholder={companyName} name="companyName"
                                    autoComplete='off'
                                    value={companyName} 
                                    disabled
                                /> 
                            </div>
                            <div class="w-full md:w-1/2 px-3 mt-4">
                            <label htmlFor="email" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Email</label>
                                <input      
                                    className='appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                                    type="email" placeholder='john.derrit@gmail.com' name="email"
                                    autoComplete='off'
                                    value={email}     
                                    disabled
                                /> 
                            </div>
                            <div class="w-full md:w-1/2 px-3 mt-4">
                            <label htmlFor="mobile" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Mobile</label>
                                <input       
                                    className='appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                                    type="text" placeholder='9888328585' name="mobile"
                                    autoComplete='off'
                                   
                                    value={mobile}
                                    disabled
                                /> 
                            </div>
                            <div class="w-full md:w-1/2 px-3 mt-4">
                            <label htmlFor="mobile" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Password</label>
                                <input 
                                    className='appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                                    type="password" placeholder='Enter Password' name="password"
                                    autoComplete='off'
                                    value={password}
                                    onChange={(e)=>{setpassword(e.target.value)}}
                                /> 
                            </div>
                            <div class="w-full md:w-1/2 px-3 mt-4">
                            <label htmlFor="mobile" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Confirm Password</label>
                                <input 
                                    className='appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                                    type="password" placeholder='Confirm Password' name="confirmPassword"
                                    autoComplete='off'
                                    
                                /> 
                            </div>
                            <div class="w-full md:w-1/2 px-3 mt-4">
                            <label htmlFor="streetAddress" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Street Address</label>
                                <input 
                                    className='appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                                    type="text" placeholder='old sabzi mandi' name="streetAddress"
                                    autoComplete='off'
                                    value={streetAddress}
                                   
                                    disabled
                                /> 
                            </div>
                            <div class="w-full md:w-1/2 px-3 mt-4">
                            <label htmlFor="country" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Country/Region</label>
                                <input 
                                    
                                    className='appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                                    type="text" placeholder='India' name="country"
                                    autoComplete='off'
                                    value={country}
                                    
                                    disabled
                                /> 
                            </div>
                            <div class="flex flex-wrap -mx-1.4 mb-2 mt-2">
                                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="city">
                                    City
                                    </label>
                                    <input class="appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="city" name="city" type="text" placeholder="Albuquerque" value={city}
                                   
                                    disabled
                                    />
                                </div>
                            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="state">
                                        State
                                    </label>
                                    <div class="relative">
                                    <input class="appearance-none block w-full bg-gray-200 text-black border border-gray-200 
                                    rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                    id="state" name="state" type="text" placeholder="Punjab"
                                    value={state}
                                    
                                    disabled
                                    />
                            </div>
                            </div>
                            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="zip">
                                Zip
                            </label>
                            <input class="appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="zip" type="text" name="zip" placeholder="90210"
                            value={postalCode}
                                    
                                    disabled
                            />
                            </div>
                        </div>
                        <div class="flex flex-wrap mx-3 mb-6 w-full">
                            
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="gst">
                                GST/FEIN
                            </label>
                            <input class="appearance-none block w-full bg-gray-200 text-black border border-gray-200
                             rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                             id="gst" type="text" placeholder="1234567891"
                             value={gstNumber}
                             
                             disabled
                             />             
                        </div>
                    </div>
                    <div class="md:flex md:items-center -mt-9">
                                <div class="md:w-1/3"></div>
                                <div class="md:w-2/3">
                                <button class="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
                                    Register
                                </button>
                                </div>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
        <ToastContainer />
    </div>
  )
}

export default FirstTimeRegister