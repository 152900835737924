import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Card from './Card';
import { BASE_URL } from './lib/basic';

const KPIShow = () => {

    const [registeredUsers,setRegisteredUsers] = useState(null);
    const [totalOrders,setTotalOrders] = useState(null);
    const [totalBoxReceived,setTotalBoxReceived] = useState(null);
    const [totalBoxSent,setTotalBoxSent] = useState(null);
    const [totalOrderProcessed,setTotalOrderProcessed] = useState(null);
    const getTotalRegisteredUser = async()=>{
        const response = await axios.get(`${BASE_URL}/order/totalRegisteredUser`,{
            headers:{
              Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
              }
            });

        setRegisteredUsers(response.data[0].regiteredUsers);
    };

    
    const getTotalOrders = async()=>{

        const response = await axios.get(`${BASE_URL}/order/getTotalOrders`,{
            headers:{
              Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
              }
            });
        setTotalOrders(response.data[0].totalOrders);
    };

    const getTotalBoxReceived = async()=>{

      let totalBoxReceived=0;
        const response = await axios.get(`${BASE_URL}/order//getTotalBoxReceived`,{
            headers:{
              Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
              }
            });
            if(response.data[0].totalBoxReceived != null)
              {
                totalBoxReceived=response.data[0].totalBoxReceived;
              }

        setTotalBoxReceived(totalBoxReceived)
          
    };

    const getTotalBoxSent = async()=>{

        let totalBoxSent =0;
        const response = await axios.get(`${BASE_URL}/order/totalBoxSent`,{
            headers:{
              Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
              }
            });
            if(response.data[0].totalBoxReceived != null)
            {
             totalBoxSent= totalBoxReceived=response.data[0].totalBoxSent;
            }
            setTotalBoxSent(totalBoxSent)
    };

    const getTotalProcessedOrder =async()=>{
        const response = await axios.get(`${BASE_URL}/order/getTotalProcessedOrder`,{
            headers:{
              Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
              }
            });
        setTotalOrderProcessed(response.data[0].processedOrder);
        
    };
    // hello
    useEffect(()=>{
        getTotalRegisteredUser();
        getTotalOrders();
        getTotalBoxReceived();
        getTotalBoxSent();
        getTotalProcessedOrder();
    },[]);
  return (
    <div className='p-5 mt-10'>
    
    <div className='flex space-x-8 justify-center'>
      <Card title="Registered Users" data={registeredUsers}/>
      <Card title="Total Order Placed" data={totalOrders} />
      <Card title="Boxes Received" data={totalBoxReceived}/>
    </div>
    <div className='flex space-x-8 mt-10 justify-center'>
      <Card title="Total Boxes Sent" data={totalBoxSent}/>
      <Card title="Total Order Processed" data={totalOrderProcessed} />
    </div>
    
    </div>
  )
}

export default KPIShow;