import React, { useEffect, useState,useRef} from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import fedexDataAtom from '../atoms/fedexData';
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from './lib/basic';
import uuid from 'react-uuid';
const OrdersTable = () => {
    const [orderNumber,setOrderNumber]=useState("");
    const [fedexData,setFedexData] = useRecoilState(fedexDataAtom);

    const recordRef = useRef(null);
    const [orderDetails,setOrderDetails] = useState([]);
    const [toggle,setToggle] =useState(false);
    const [showDetails,setShowDetails] = useState({orderNumber:""});
    const [vinDetails,setVinDetails] = useState(null);

    const [allStatus,setAllStatus] = useState([]);
    const [totalRecords,setTotalRecords] = useState(0);
    const [selectedStatus,setSelectedStatus] = useState(""); 
    const [viewOrderHistory,setViewOrderHistory] = useState(false);
    const [orderHistory,setOrderHistory] = useState();
    let [currentPage,setCurrentPage] = useState(0);
    const currentCount =10;
    let totalNumberOfPages;
    const getOrders = async()=>{

        const res = await axios.get(`${BASE_URL}/order/allorders/${currentPage}/${currentCount}`,{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }
        });
        
        if(res)
        {
        setOrderDetails(res.data.data.cur_records);
        setTotalRecords(res.data.data.total_count);
        }
    };

    const getStatus = async()=>{
        const res = await axios.get(`${BASE_URL}/order/status`,{ headers:{
            Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
     }});

        if(res.status == "200")
            {
                setAllStatus(res.data);
            }
        else
            {
                toast.error(res.data.message,{
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
    };

   const getOrderDetails = async (event)=>{
        const res = await axios.get(`${BASE_URL}/order/getvin/${event.target.id}`,{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }
        });
            if(res.status == "200")
                {
                    setToggle(true);
                    setVinDetails(res.data);
                    setShowDetails({orderNumber:event.target.id});
                 
                }
            else
            {
                toast.error(res.data.message,{
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
        const response = await axios.post(`${BASE_URL}/order/downloadorderdetails`,{orderNumber:event.target.id},{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }
        });

        if(response.data)
            {
                setFedexData(response.data);
            }
        const orderHistoryResponse = await axios.post(`${BASE_URL}/order/orderhistory`,{orderNumber:event.target.id},{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }
        });
        if(orderHistoryResponse.data)
            {
                setOrderHistory(orderHistoryResponse.data);
            }

    };

    const updateHandler=async()=>{
        const res = await axios.post(`${BASE_URL}/order/updateOrder`,{
            orderNumber:showDetails.orderNumber,
            status:selectedStatus,
            userId:JSON.parse(localStorage.getItem('userData')).userId,
            actionDate:moment().format('Do-MMM-YYYY h:mm a')
        },{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }
        });

        toast.success(res.data,{
            position: "top-center",
            autoClose: 9000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
    };

    const previousPageHandler =()=>{
        if(currentPage>0)
        {
            setCurrentPage(--currentPage);
        }
    };

    const nextPageHandler = ()=>{
        setCurrentPage(++currentPage);
    };

    const orderHistoryToggle =()=>{

        setViewOrderHistory(!viewOrderHistory);
    };

    const resetHandler =()=>{

            getOrders();
            setOrderNumber("");
    };

    const getSearchOrderDetails = async()=>{
        const resposne = await axios.post(`${BASE_URL}/order/searchOrder/${currentPage}/${currentCount}`,{orderNumber},{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
           }});
           if(resposne)
            {
                console.log(resposne.data.data.cur_records)
                setOrderDetails(resposne.data.data.cur_records);
                setTotalRecords(resposne.data.data.total_count);
            }
            else
            {
                toast.error("Order Not Found",{
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            } 
    };
    
    useEffect(()=>{
        getStatus();
    },[]);

    useEffect(()=>{
        getOrders();
    },[currentPage]);

    totalNumberOfPages = Math.ceil(totalRecords/10);
  return (
      <>
    <div className="flex items-center justify-center mt-4">
    <div className="flex ">
        <input type="text" className="px-4 py-2 w-80" placeholder="Search Your Order" onChange={(e)=>{setOrderNumber(e.target.value)}} value={orderNumber}/>
        <button className="px-4 text-white bg-gray-600 border-l mr-2" onClick={getSearchOrderDetails}>
            Search
        </button>
        <button className="px-6 text-white bg-gray-600 border-l" onClick={resetHandler}>
            Reset
        </button>
    </div>
    <ToastContainer />
    </div>
    <div className="flex flex-col mt-5">
        <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow-md sm:rounded-lg">
                <table className="min-w-full">
                    <thead className="bg-gray-200 dark:bg-gray-700">
                        <tr>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Order Number
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Dealer Name
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Total Airbags
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Order Date
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                User Email
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Phone Number
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                   { orderDetails.map((order)=>(
                    <>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={uuid()}>
                            <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {order.OrderNumber}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                {order.fullName}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                {order.totalAirbags}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                {order.creationDate}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                {order.email}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                {order.mobile}
                            </td>
                            <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                             {(showDetails.orderNumber == order.OrderNumber) && (toggle) ? <button id={order.OrderNumber} onClick={()=>{setToggle(!toggle)}} className="text-blue-600 dark:text-blue-500 hover:underline">Hide</button>
                               : <button id={order.OrderNumber}  onClick={(event)=>{getOrderDetails(event)}} className="text-blue-600 dark:text-blue-500 hover:underline">Info</button>
                             }
                            </td>
                        </tr>
                       
                      {  (showDetails.orderNumber == order.OrderNumber) && (toggle) && (

                        <tr className=''>
                                <div className='w-[150%] ml-20 mt-5'>
                                        <h1 className='font-bold'>User Details</h1>
                                        <p>Name : {vinDetails[0].fullName}</p>
                                        <p>Address : {vinDetails[0].streetAddress}</p>
                                        <p>OrderNumber : {vinDetails[0].orderNumber}</p>
                                        <p><b>Total AirBags</b>: {vinDetails[0].totalAirbags}</p> 
                                        <p><b>Status </b>: {allStatus[vinDetails[0].status -1].name}</p>
                                        <div className='flex space-x-2  space-y-2 items-center'>
                                        <label for="status">Status:</label>
                                            <select name="status" id="status" onChange={(e)=>{setSelectedStatus(e.target.value)}}>
                                              {  
                                                 allStatus.map((value)=>(
                                                    <option value={value.id} selected={ allStatus[vinDetails[0].status -1].name == value.name &&  "selected" } key={value.id}>{value.name}</option>
                                                 ))
                                              }
                                            </select>
                                     </div>
                                         <div className='flex flex-grow w-full item-center p-5'>            
                                        <table className="min-w-full">
                                        <thead className="bg-gray-200 dark:bg-gray-700">
                                        <tr>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                                Vin Number
                                            </th>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                                Passenger Side
                                            </th>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                                Driver Side
                                            </th>   
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                                Car Details
                                            </th>                  
                                        </tr>
                                    </thead>
                                    <tbody>
                    {vinDetails.map((vin)=> (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={uuid()}>
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {vin.vinNumber}
                        </td>
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {vin.passangerSide}
                        </td>
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {vin.driverSide}
                        </td>
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                           {`${vin.model}-${vin.modelyears}`}
                        </td>       
                     </tr>
                    ))}   
                    <div className='flex flex-grow items-center'>
                        <button type='button' className='mt-4 border p-2 bg-green-400' onClick={updateHandler}>Update</button>   
                        
                    { fedexData.length > 0  && 
                    <CSVLink data={fedexData} filename={`${fedexData[1][10]}_fedexDetails`}>
                            <button type='button' className='mt-4 border ml-5 p-2 bg-blue-400'>Download</button> 
                    </CSVLink>}
                    </div>
                    <p className='text-blue-500 underline cursor-pointer mt-8 text-lg' onClick={orderHistoryToggle}>{viewOrderHistory ? "Hide Order History" : "Track Order History"}</p> 
                    {
                        viewOrderHistory && (
                           
                                <table className="min-w-full">
                    <thead className="bg-gray-200 dark:bg-gray-700">
                        <tr>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                History type
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Action Date
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Action By
                            </th>
                           
                        </tr>
                    </thead>
                    <tbody>
                    {orderHistory.map((history)=>(
                             <tr key={uuid()}>
                             <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                 {history.historyType}
                             </td>
                             <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {history.actionDate}
                             </td>
                             <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {history.actionBy}
                             </td>
                         </tr>

                    ))}
                       
                    </tbody>
                    </table>
                           
                        )
                    }
                    
                    </tbody>
                </table>
                </div>
                </div>
                                
                   
                 </tr>) 
                      }
                    </>
                        ))}
                       
                    </tbody>
                </table>
                
            
        </div>
    </div>
    <div className='inline-block py-2 min-w-full sm:px-6 flex lg:px-8 '>
        <p>Page {currentPage+1}/{totalNumberOfPages}</p>
    </div>
    <div className="flex flex-col items-center">
                 <span className="text-sm text-gray-700 dark:text-gray-400 mt-">
                    Showing <span className="font-semibold text-gray-900 dark:text-white">{(currentPage*10)+1}</span> to <span className="font-semibold text-gray-900 dark:text-white">{currentPage*10 + 10 > totalRecords ? (currentPage*10 + 10)-(currentPage*10 + 10 - totalRecords) :currentPage*10 + 10  }</span> of <span className="font-semibold text-gray-900 dark:text-white">{totalRecords}</span> Entries
                </span>
    <div className='inline-block py-6 min-w-full sm:px-6 flex lg:px-8 text-center space-x-5 justify-center'>
                      <button type='button' onClick={previousPageHandler} className={currentPage == 0 ? 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-white-500 bg-gray-400 rounded-lg border border-gray-300 opacity-70 cursor-no-drop dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400  dark:hover:text-white'
      : 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'} disabled={currentPage == 0 ? true : false}>
                        <svg className="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
                            Previous</button>
                      <button type='button' onClick={nextPageHandler} 
                      className={currentPage+1 == totalNumberOfPages ? 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-white-500 bg-gray-400 rounded-lg border border-gray-300 opacity-70 cursor-no-drop dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400  dark:hover:text-white'
                      : 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'} disabled={currentPage+1 == totalNumberOfPages ?true :false}>
                          Next
                        <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                       </button>

    </div>
    </div>
    <ToastContainer />
</div>
</>
  )
}

export default OrdersTable