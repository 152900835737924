import React from 'react'

const Card = ({title,data}) => {
  return (
    <div  className="block cursor-pointer w-[22%] h-[9rem] flex flex-col items-center justify-center p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 className="mb-2 text-2xl text-center font-bold  tracking-tight text-gray-700 dark:text-white ">{title}</h5>
        <p className="font-normal text-2xl font-bold text-center text-gray-900 dark:text-gray-900">{data}</p>
    </div>
  )
}

export default Card;