import React from 'react';
import {Link, useNavigate,useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../shared/context/auth-context';
import logo from "../assets/recycleVinLogo.png";
const Navbar = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const inActive = "block py-2 pr-4 pl-3  text-gray-700 md:text-gray-700 rounded md:bg-transparent md:p-0 dark:text-white hover:text-blue-700";
  const active = "block py-2 pr-4 pl-3  text-blue-700 md:text-blue-700 rounded md:bg-transparent md:p-0 dark:text-white hover:text-blue-7 00";
  
  const signOutHandler = ()=>{
    auth.logout();
    navigate("/", { replace: true });
  };
  return (
    <>
    <nav className="bg-slate-400 border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-800">
    <div className="flex flex-wrap justify-between items-center mx-auto">
    <Link to="/dashboard" className="flex items-center">
        <img src={logo} alt="Lacero Logo" className="mr-3 h-11" />
        {/* <span className="self-center text-lg font-semibold whitespace-nowrap dark:text-white">RecycleVinApp</span> */}
    </Link>
    
    <div className="hidden justify-between items-center w-full md:flex md:w-auto md:order-1" id="mobile-menu-2">
      <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
     {JSON.parse(localStorage.getItem('userType')).userRole!== "admin" && <> 
        <li>
          <Link to="/dashboard" className={currentPath === "/dashboard" ? active : inActive}>Home</Link>
        </li>
        <li>
          <Link to="/modalInfo" className={currentPath === "/modalInfo" ? active : inActive}>Info</Link>
        </li>
        <li>
          <a href="https://www.lacerosolutions.com/" target="_blank" rel="noopener noreferrer" className="block py-2 pr-4 pl-3 text-gray-700  border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</a>
        </li>
        <li>
        <Link to="/myorders"  className={currentPath === "/myorders" ? active : inActive}>Orders</Link>
        </li>
        </>
      }
  { JSON.parse(localStorage.getItem('userType')).userRole === "admin" && 
      <> 
        <li>
          <Link to="/dashboard" className={currentPath === "/dashboard" ? active : inActive}>Home</Link>
        </li>
         <li>
          <Link to="/orders"  className={currentPath === "/orders" ? active : inActive}>Orders</Link>
        </li>
        <li>
          <Link to="/hubspotData"  className={currentPath === "/hubspotData" ? active : inActive}>Hubspot Data</Link>
        </li> 
      </>
   }
        <li>
          <p onClick={signOutHandler} className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 cursor-pointer">Sign Out</p>
        </li>
      </ul>
    </div>
    </div>
  </nav>
  </>
  )
};

export default Navbar