import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { BASE_URL } from './lib/basic';
const UserOrderTable = () => {

    const [userOrder,setUserOrder] = useState(null);
    const [toggle,setToggle]= useState(false);
    const [showDetails,setShowDetails] = useState({orderNumber:""});
    const [vinDetails,setVinDetails] = useState(null);

    const [totalRecords,setTotalRecords] = useState(0);
    let [currentPage,setCurrentPage] = useState(0);
    const currentCount =10;
    let totalNumberOfPages;

    const getUserOrders = async()=>{

        const res = await axios.post(`${BASE_URL}/order/userorder/${currentPage}/${currentCount}`,{userId:JSON.parse(localStorage.getItem('userData')).userId},{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }
        });
        if(res)
            {
                setUserOrder(res.data.data.cur_records);
                setTotalRecords(res.data.data.total_count);
            }
       
    };

    const getOrderDetails = async (event)=>{

        const res = await axios.get(`${BASE_URL}/order/getvin/${event.target.id}`,{
            headers:{
                Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
         }
        });
        if(res.status == "200")
        {
            setToggle(true);
            setVinDetails(res.data);
            setShowDetails({orderNumber:event.target.id});
        }
        else
        {
            toast.success(res.data.message,{
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
        }
       
    };
// LocalDev
    const previousPageHandler =()=>{

        if(currentPage>0)
        {
            setCurrentPage(--currentPage);
        }
        
    };

    const nextPageHandler = ()=>{
        setCurrentPage(++currentPage);
    };

    useEffect(()=>{
       getUserOrders();
    },[currentPage]);
    totalNumberOfPages = Math.ceil(totalRecords/10);
  return (
    <div className="flex flex-col mt-5">
        <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow-md sm:rounded-lg">
            {userOrder && <table className="min-w-full">
                    <thead className="bg-gray-200 dark:bg-gray-700">
                        <tr>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Order Number
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Creation Date
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                Order Status
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                               Total VIN's
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                               Total AirBags
                            </th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                               Action
                            </th>    
                        </tr>
                    </thead>
                    <tbody>
            {userOrder.map((order)=>(
                    <>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={Math.random()}>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                        {order.orderNumber}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                        {order.creationDate}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                        {order.name}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                        {order.totalvin}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                        {order.totalAirbags}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                   {(showDetails.orderNumber == order.orderNumber) && (toggle) ? <button id={order.OrderNumber} onClick={()=>{setToggle(!toggle)}} className="text-blue-600 dark:text-blue-500 hover:underline">Hide</button>
                                    : <button id={order.orderNumber} className='text-blue-600 dark:text-blue-500 hover:underline' onClick={(event)=>{getOrderDetails(event)}}>Info</button> 
                                   }
                            </td>
                        </tr>
                        { (showDetails.orderNumber == order.orderNumber) && (toggle) && (
                              <tr>
                                  <table className='w-[200%] ml-20 mt-5'>
                                      <thead className='bg-gray-200 dark:bg-gray-700'>
                                          <tr>
                                              <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">VIN</th>
                                              <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">Passenger Side</th>
                                              <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">Driver Side</th>
                                              <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">Car Details</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                       {vinDetails.map((vin)=>(
                                       <tr>
                                                <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">{vin.vinNumber}</td>
                                                <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">{vin.passangerSide}</td>
                                                <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">{vin.driverSide}</td>
                                                <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">{`${vin.model}-${vin.modelyears}`}</td>
                                       </tr>
                                       ))}
                                      </tbody>
                                  </table>
                              </tr>
                              )  
                        }


                        </>
            ))       

                }
                </tbody>
                </table> 
            }
                </div>
                </div>
    <div className='inline-block py-2 min-w-full sm:px-6 flex lg:px-8 '>
        <p>Page {currentPage+1}/{totalNumberOfPages}</p>
    </div>
    <div className="flex flex-col items-center">
                 <span className="text-sm text-gray-700 dark:text-gray-400 mt-">
                    Showing <span className="font-semibold text-gray-900 dark:text-white">{(currentPage*10)+1}</span> to <span className="font-semibold text-gray-900 dark:text-white">{currentPage*10 + 10 > totalRecords ? (currentPage*10 + 10)-(currentPage*10 + 10 - totalRecords) :currentPage*10 + 10  }</span> of <span className="font-semibold text-gray-900 dark:text-white">{totalRecords}</span> Entries
                </span>            
                <div className='inline-block py-6 min-w-full sm:px-6 flex lg:px-8 text-center space-x-5 justify-center'>
                   <button type='button' onClick={previousPageHandler} className={currentPage == 0 ? 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-white-500 bg-gray-400 rounded-lg border border-gray-300 opacity-70 cursor-no-drop dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400  dark:hover:text-white'
                     : 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'} disabled={currentPage == 0 ? true : false}>
                     <svg className="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
                         Previous</button>
                   <button type='button' onClick={nextPageHandler} 
                   className={currentPage+1 == totalNumberOfPages ? 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-white-500 bg-gray-400 rounded-lg border border-gray-300 opacity-70 cursor-no-drop dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400  dark:hover:text-white'
                   : 'inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'} disabled={currentPage+1 == totalNumberOfPages ?true :false}>
                       Next
                     <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
        </div>        
</div>     
        <ToastContainer />
        </div>
  )
}
export default UserOrderTable