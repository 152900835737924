import {useState,useCallback,useEffect} from "react";
import './App.css';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import DashBoard from './pages/DashBoard';
import SignIn from './pages/SignIn';
import {AuthContext} from "./shared/context/auth-context";
import Orders from "./pages/Orders";
import HubspotData from "./pages/HubspotData";
import userRoleAtom from "./atoms/userRole";
import {useRecoilValue,useRecoilState} from 'recoil';
import vinDetailsAtom from "./atoms/vinDetails";
import MyOrders from "./pages/MyOrders";
import InfoTable from "./components/InfoTable";
import {ToastContainer} from 'react-toastify';
let logoutTimer;

function App() {
  // UserRole Value From Atom
  const userRole = useRecoilValue(userRoleAtom);

  const [vinDetails,setVinDetails] = useRecoilState(vinDetailsAtom);

  const initalToken = localStorage.getItem('token') ? localStorage.getItem('token') : null;
  
  const initalUserRole = localStorage.getItem('uType') ? localStorage.getItem('uType') : "customer"; 

  // State for the Context API
    const [token,setToken] = useState(initalToken);
    const [userId,setUserId] =useState(null);
    const [userEmail,setUserEmail] = useState(null);
    const [userType,setUserType]=useState(initalUserRole);
    const [isLoggedIn,setIsLoggedIn]=useState(false)
    const [tokenExpirationDate,setTokenExpirationDate]=useState();
    
    // testing Purpose
    
    const login = useCallback((uid,token,uType,email,expirationDate)=>{
      localStorage.setItem('token',token);
      localStorage.setItem('uType',uType);
      setUserId(uid);
      setToken(token);
      setUserType(uType)
      setUserEmail(email);
      setIsLoggedIn(true)
      const tokenExpirationDate = expirationDate || new Date(new Date().getTime()+ 1000 * 60 * 60);
      setTokenExpirationDate(tokenExpirationDate);
      localStorage.setItem('userData',JSON.stringify({userId:uid,token:token,userType:uType,userEmail:email,expiration:tokenExpirationDate.toISOString()}));
      
    },[]);

    const logout =useCallback(()=>{
      setToken(null);
      setUserId(null);
      setIsLoggedIn(false);
      setUserType(null);
      setUserEmail(null);
      setTokenExpirationDate(null);
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
      localStorage.removeItem('uType');
      localStorage.removeItem('userType');
      setVinDetails([]);
    },[]);

    useEffect(()=>{
      if(token && tokenExpirationDate){
        const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();  
        logoutTimer= setTimeout(logout,remainingTime);
      }
      else
      {
        clearTimeout(logoutTimer);
      }

    },[token,logout,tokenExpirationDate]);



    useEffect(()=>{
      const storedData= JSON.parse(JSON.stringify(localStorage.getItem('userData')));
      if(storedData && storedData.token && new Date(storedData.expiration) > new Date()){
        login(storedData.userId,storedData.token,new Date(storedData.expiration));

      };
   },[login]);

   let routes;
 

   if(token) {
      if(userType == "admin")
        {
          routes=(<BrowserRouter>
          
            <Routes>    
                <Route path="/" exact element={<SignIn />} />
                    <Route exact path="/dashboard" element={<DashBoard />} />  
                    <Route exact path="/orders" element={<Orders />} />  
                    <Route exact path="/hubspotData" element={<HubspotData />} />   
            </Routes>  
        </BrowserRouter> )
        }
        else
        {
          routes = (
            <BrowserRouter>
            <ToastContainer />
            <Routes>       
              <Route path="/" element={<SignIn />} />
              <Route exact path="/modalInfo" element={<InfoTable />} />
                <Route exact path="/dashboard" element={<DashBoard />} />
                <Route exact path="/myorders" element={<MyOrders />} />  
            </Routes>
          </BrowserRouter> 
           );
        }
      }
   else 
   {
    routes=(  
        <BrowserRouter>
          <Routes>       
            <Route path="*" element={<SignIn />} exact/>
          </Routes>
        </BrowserRouter>  
        
   );
  
   }

  
 

  return (
        <AuthContext.Provider 
        value={{
          isLoggedIn:!!token,
          userId: userId,
          token:token,
          login: login,
          logout: logout,
          email:userEmail
        }}>
 
            {routes}
          
        </AuthContext.Provider>
    
  );
}

export default App;
